import React, {Component} from "react";
import List from "../../../components/List/List";
import {ApiConstantsContext} from "../../../variables/ApiConstantsContext";
import {UniversalAudioFilter} from "../../../models/UniversalAudio";
import {API} from "aws-amplify";


export default class UniversalAudio extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/universal-audio/add';
        this.editPath = '/admin/universal-audio/edit';
        this.state = {
            categories: [],
        }
    }

    componentDidMount() {
        API.get('admin', "/admin/universal-audio/categories")
            .then(data => {
                let categories = data.items.reduce(function (obj, item) {
                    obj[item.id] = `${item.name}`;
                    return obj;
                }, {});
                this.setState({
                    categories: categories

                })
            }).catch(error => {
            console.error(error)
        })
    }


    render() {
        return (
            <List
                name="Audio"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/universal-audio"
                listApiUrl="/admin/universal-audio"
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'Internal name', row: 'internal_name'},
                    {col: 'Name', row: 'name'},
                    {col: 'Category', row: 'category_name'}
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={UniversalAudioFilter(this.state.categories)}
                userHasPermission={this.props.userHasPermission}
            >
            </List>
        );
    }
}
