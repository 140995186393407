const WorkoutFilter = {
    ids: {
        type: 'input',
        value: '',
        placeholder: 'Ids',
        requestNormalizer: (data) => {
            return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
        },
    },
    tag: {
        type: 'input',
        value: '',
    }
};

export default WorkoutFilter;