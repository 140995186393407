import {BaseIdAndNameFilter} from "../../../models/Base";
import {BaseTranslationWithoutEnComponent} from "../../Generic/BaseTranslationComponent";

export default class TrainingPlanTranslation extends BaseTranslationWithoutEnComponent {
    constructor(props) {
        super(props);
        this.name = "Training Plan Translations"
        this.addPath = "/admin/translations/training_plans/add";
        this.editPath = "/admin/translations/training_plans/edit";
        this.removeUrl = "/admin/translations/training_plans";
        this.listApiUrl = "/admin/translations/training_plans";
    }

    generateFilter = () => {
        return BaseIdAndNameFilter()
    }
}
