export const AudioCategoryModel = () => {
    return {
        name: {
            type: 'input',
            validationRules: ['required', {'regex': "^([a-z0-9]+)(_[a-z0-9]+)*$"}],
            value: '',
            md: 4
        },
    }
};
