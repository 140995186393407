export const WorkoutTranslationModel = (workout_id, language) => {
    return {
        workout_id: {
            value: workout_id,
            hidden: true
        },
        language: {
            value: language,
            hidden: true
        },
        name: {
            type: 'input',
            validationRules: 'required',
            value: "",
        },
    };
}