import React, {Component} from "react";
import List from "../../../components/List/List";
import {ApiConstantsContext} from "../../../variables/ApiConstantsContext";
import defaults from "../../../defaults";
import EditButton from "../../../components/List/EditButton";

let g = defaults.userGroups

export default class AudioCategory extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/universal-audio/categories/add';
        this.editPath = '/admin/universal-audio/categories/edit';
    }


    render() {
        return (
            <List
                name="Category"
                addPath={this.addPath}
                editPath={this.editPath}
                actions={[
                    (list, data) => {
                        return <EditButton
                            editPath={list.props.editPath}
                            id={data.id}
                            allowedGroups={list.props.allowedEdit || [g.admin, g.editor]}
                        />
                    },
                    ]}
                listApiUrl="/admin/universal-audio/categories"
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'Name', row: 'name'},
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                userHasPermission={this.props.userHasPermission}
            >
            </List>

        );
    }
}
