import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import {ExerciseTranslationModel} from "../../../models/ExerciseTranslation";


export default class ExerciseTranslationAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Exercise Translation"
        this.url = "/admin/translations/exercises"
    }

    getTitle = () => {
        return this.isEdit() ? ((data) => {return `Edit ${this.name} for ${data.language}`}) :
            `Add ${this.name} for ${this.props.match.params.language}`
    }

    getEntity = () => {
        let language = this.props.match.params.language
        let exercise_id = this.props.match.params.exercise_id
        if (typeof exercise_id !== undefined){
            exercise_id = parseInt(exercise_id)
        }
        return ExerciseTranslationModel(
            exercise_id || null,
            language || null);
    }
}
