import {buildSelector} from "../utils/Utils";

export const UniversalAudioTranslationModel = (universal_audio_track_id, language) => {
    return {
        universal_audio_track_id: {
            value: universal_audio_track_id,
            hidden: true
        },
        language: {
            value: language,
            hidden: true
        },
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        audio_url: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: null,
        }
    };
}

export const UniversalAudioTranslationFilter = (categories) => {
    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            }
        },
        name: {
            type: 'input',
            value: '',
        },
        category_id:  buildSelector(
            categories,
            'select',
            (data) => {
                return parseInt(data.value);
            }),
    };
}