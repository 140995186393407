import {buildSelector, constToSelectOptions} from "../utils/Utils";
import {TrainingPlanWorkout} from "./TrainingPlanWorkout";

export const TrainingPlanModel = (workouts, constants) => {
    return {
        id: {
            value: null,
            hidden: true
        },
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        system_name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        male_preview: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        female_preview: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        description: {
            type: 'input',
            validationRules: 'required',
            value: '',
            selectProps: {
                rows: 7,
                componentClass: "textarea",
                as: "textarea",
            },
        },
        category: buildSelector(constToSelectOptions(constants, 'training_plan_category'), 'select'),

        activity_type: buildSelector(constToSelectOptions(constants, 'activity_type'), 'select'),
        workouts: {
            type: 'collection',
            value: [],
            prototype: TrainingPlanWorkout(workouts),
            onChangeEvent: 'handleCollection',
            requestNormalizer: (data) => {
                return data.map(a => parseInt(a.id))
            }
        },
    };
}
