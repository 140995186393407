import React, {Component} from "react";
import List from "../../components/List/List";
import WorkoutFilter from "../../models/WorkoutFilter";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {constToSelectOptions} from "../../utils/Utils";

export default class Workout extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/workouts/add';
        this.editPath = '/admin/workouts/edit';
    }

    render() {
        let constants = this.context.constants;
        let workoutTypes = constToSelectOptions(constants, 'workout_type')
        let workoutBlockTypes = constToSelectOptions(constants, 'workout_block_type')
        let pace = constToSelectOptions(constants, 'pace')

        return (
            <List
                name="Workout"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/workouts"
                listApiUrl='/admin/workouts'
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'Name', row: 'name'},
                    {col: 'Tag', row: 'tag'},
                    {
                        col: 'Type', row: 'type', normalizer: (data) => {
                            return workoutTypes[data.type]
                        }
                    },
                    {
                        col: 'Blocks', row: 'pace', normalizer: (data) => {
                            return data.blocks.map((a, i) =>
                                <div key={i}>{`${i + 1}. ${a.name} | Type: ${workoutBlockTypes[a.block_type]} | Pace: ${pace[a.pace]} | Time: ${a.time || 0} | Distance: ${a.distance || 0} | Heart: ${a.min_heart_rate || 0}-${a.max_heart_rate || 0} | Repetitions: ${a.repetitions}`} </div>)
                        }
                    },
                    {col: 'Total time', row: 'time'}
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={WorkoutFilter}
                userHasPermission={this.props.userHasPermission}
            >
            </List>

        );
    }
}
