import {ExerciseModel} from "../../models/Exercise";
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";


export default class ExerciseAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Exercise"
        this.url = "/admin/exercises"
        this.redirectUrl = "/admin/exercises"
    }

    getEntity = () => {
        return ExerciseModel(this.context.constants);
    }
}
