import React from 'react';
import {WorkoutBlock} from "../../models/WorkoutBlock";
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {API} from "aws-amplify";
import debounce from "debounce-promise";

export default class WorkoutBlockAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Workout Block"
        this.url = "/admin/workout-blocks"

        this.debouncedLoadOptions = debounce(this.loadExercises, 2000);
    }

    loadExercises = (input) => {
        let queryParams = {
        }
        if (input.length > 0) {
            if (isNaN(input)) {
                queryParams["name"] = input;
            } else {
                queryParams["ids"] = JSON.stringify([parseInt(input)]);
            }
        }

        return API.get("admin", "/admin/exercises", {
            queryStringParameters: queryParams,
        })
            .then((data) => {

                let exercises = data.items.reduce(function (obj, item) {
                    obj[item.id] = item;
                    return obj;
                }, {});
                return Object.values(exercises);
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    };


    getEntity = () => {
        return WorkoutBlock(this.context.constants, this.debouncedLoadOptions);
    }

    render() {
        return React.cloneElement(
            super.render(),
            {md: 12}
        )
    }
}