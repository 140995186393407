import React from 'react';
import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import {AudioCategoryModel} from "../../../models/UniversalAudioCategory";

export default class AudioCategoryAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Universal audio categories"
        this.url = "/admin/universal-audio/categories"

    }

    getEntity = () => {
        return AudioCategoryModel();
    }

    render() {
        return React.cloneElement(
            super.render(),
            {md: 12}
        )
    }
}