import React from 'react';
import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import {UniversalAudioModel} from "../../../models/UniversalAudio";
import {API} from "aws-amplify";

export default class UniversalAudioAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Universal audio"
        this.url = "/admin/universal-audio"

        this.state = {
            categories: [],
        }
    }

    componentDidMount() {
        API.get('admin', "/admin/universal-audio/categories")
            .then(data => {
                let categories = data.items.reduce(function (obj, item) {
                    obj[item.id] = `${item.name}`;
                    return obj;
                }, {});
                this.setState({
                    categories: categories

                })
            }).catch(error => {
            console.error(error)
        })
    }

    getEntity = () => {
        return UniversalAudioModel(this.state.categories);
    }

    render() {
        return React.cloneElement(
            super.render(),
            {md: 12}
        )
    }
}