import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import {UniversalAudioTranslationModel} from "../../../models/UniversalAudioTranslation";


export default class UniversalAudioTranslationAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Exercise Translation"
        this.url = "/admin/translations/universal-audio"
    }

    getTitle = () => {
        return this.isEdit() ? ((data) => {return `Edit ${this.name} for ${data.language}`}) :
            `Add ${this.name} for ${this.props.match.params.language}`
    }

    getEntity = () => {
        let language = this.props.match.params.language
        let universal_audio_track_id = this.props.match.params.universal_audio_track_id
        if (typeof universal_audio_track_id !== undefined){
            universal_audio_track_id = parseInt(universal_audio_track_id)
        }
        return UniversalAudioTranslationModel(
            universal_audio_track_id || null,
            language || null);
    }

}
