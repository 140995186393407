import {buildSelector, constToSelectOptions} from "../utils/Utils";
import {WorkoutBlockIds} from "./WorkoutBlock";


export const Workout = (constants, loadOptions) => {
    return {
        id: {
            value: null,
            hidden: true,
        },
        name: {
            type: 'input',
            validationRules: 'required',
            value: "",
        },
        type: buildSelector(constToSelectOptions(constants, 'workout_type'), 'select', undefined, undefined, 4),
        tag: {
            type: 'input',
            validationRules: 'required',
            value: '',
            md: 4
        },
        blocks: {
            type: 'table_collection',
            value: [],
            prototype: WorkoutBlockIds(constants, loadOptions),
            onChangeEvent: 'handleCollection',
            validationRules: 'required',
            requestNormalizer: function (data) {
                return data.map(elem => elem.id);
            },
        },
    }
};

