import React, {Component} from "react";
import {Col, Grid, Row, Table} from "react-bootstrap";
import Card from "../../components/Card/Card";
import JSONPretty from 'react-json-pretty';


export default class UserPaymentInfo extends Component {
    constructor(props) {
        console.log(props)
        super(props);
        this.state = {
            paymentInfo: props.paymentInfo || [],
            userId: props.userId
        }
    }

    render() {
        console.log(this.state.paymentInfo)
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={`Payment info for user ${this.state.userId}`}
                            />
                            <Card
                                ctTableResponsive
                                content={this.state.paymentInfo.length === 0 ?
                                    <p>No user payment info</p> :
                                    ( this.state.paymentInfo.map((order, index) => {
                                            return <Table hover bordered>
                                                <tbody>
                                                <tr>
                                                    <th>external_id</th>
                                                    <td>{order.external_id}</td>
                                                </tr>
                                                <tr>
                                                    <th>amount</th>
                                                    <td>{order.amount/100}</td>
                                                </tr>
                                                <tr>
                                                    <th>currency</th>
                                                    <td>{order.currency}</td>
                                                </tr>
                                                <tr>
                                                    <th>payment_type</th>
                                                    <td>{order.payment_type}</td>
                                                </tr>
                                                <tr>
                                                    <th>created_at</th>
                                                    <td>{order.created_at}</td>
                                                </tr>
                                                <tr>
                                                    <th>updated_at</th>
                                                    <td>{order.updated_at}</td>
                                                </tr>
                                                <tr>
                                                    <th>status</th>
                                                    <td>{order.status}</td>
                                                </tr>
                                                <tr>
                                                    <th>external_status</th>
                                                    <td>{order.external_status}</td>
                                                </tr>
                                                <tr>
                                                    <th>is_email_sent_upsell</th>
                                                    <td>{order.is_email_sent_upsell}</td>
                                                </tr>
                                                <tr>
                                                    <th>is_invoice_sent</th>
                                                    <td>{order.is_invoice_sent}</td>
                                                </tr>
                                                <tr>
                                                    <th>parent_id</th>
                                                    <td>{order.parent_id}</td>
                                                </tr>
                                                <tr>
                                                    <th>upsale</th>
                                                    <td>{order.upsale}</td>
                                                </tr>
                                                <tr>
                                                    <th>payment_data</th>
                                                    <td><JSONPretty id="json-pretty" data={JSON.stringify(order.payment_data)}></JSONPretty></td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        })
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}
