import {buildSelector, constToSelectOptions} from "../utils/Utils";

export const TrainingPlanClusterKeys = [
    'goal',
    'fitness_level',
    'age_group',
    'bmi',
    'activity_type'

];

const TrainingPlan = (trainingPlans) => {
    return {
        id: buildSelector(trainingPlans, 'select',
            function (data) {
                return data.value;
            },
            function (data) {
                return {value: data, label: data}
            }
        )

    }
}

export const TrainingPlanClusterModel = (trainingPlans, constants) => {
    let model = {
        id: {
            value: null,
            hidden: true
        },
        training_plans: {
            type: 'collection',
            value: [],
            prototype: TrainingPlan(trainingPlans),
            onChangeEvent: 'handleCollection',
            validationRules: 'required',
            requestNormalizer: (data) => {
                return data.map(a => parseInt(a.id))
            }
        }
    };
    return {...model, ...TrainingPlanClusterFilter(constants)};
}

export const TrainingPlanClusterFilter = (constants) => {
    let model = {};

    for (const field_name of TrainingPlanClusterKeys) {
        model[field_name] = buildSelector(constToSelectOptions(constants, field_name))
    }
    return model;
};
