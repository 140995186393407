export const TrainingPlanTranslationModel = (training_plan_id, language) => {
    return {
        training_plan_id: {
            value: training_plan_id,
            hidden: true
        },
        language: {
            value: language,
            hidden: true
        },
        name: {
            type: 'input',
            validationRules: 'required',
            value: "",
        },
        description: {
            type: 'input',
            value: '',
            validationRules: 'required',
            selectProps: {
                rows: 7,
                componentClass: "textarea",
                as: "textarea",
            },
        },
    };
}