import Workout from "./controllers/Workout/Workout.jsx";
import TrainingPlan from "./controllers/TrainingPlan/TrainingPlan.jsx";
import TrainingPlanAdd from "./controllers/TrainingPlan/TrainingPlanAdd.jsx";
import TrainingPlanEdit from "./controllers/TrainingPlan/TrainingPlanEdit";
import User from "./controllers/User/User";
import UserPaymentInfo from "./controllers/User/UserPaymentInfo";
import TrainingPlanCluster from "./controllers/TrainingPlanCluster/TrainingPlanCluster";
import TrainingPlanClusterAdd from "./controllers/TrainingPlanCluster/TrainingPlanClusterAdd";
import TrainingPlanClusterEdit from "./controllers/TrainingPlanCluster/TrainingPlanClusterEdit";
import Info from "./controllers/Info";
import WorkoutAdd from "./controllers/Workout/WorkoutAdd";
import WorkoutEdit from "./controllers/Workout/WorkoutEdit";
import Exercise from "./controllers/Exercises/Exercise.jsx"
import ExerciseAdd from "./controllers/Exercises/ExerciseAdd"
import ExerciseEdit from "./controllers/Exercises/ExerciseEdit"
import WorkoutBlock from "./controllers/WorkoutBlock/WorkoutBlock.jsx"
import WorkoutBlockAdd from "./controllers/WorkoutBlock/WorkoutBlockAdd"
import WorkoutBlockEdit from "./controllers/WorkoutBlock/WorkoutBlockEdit";
import ExerciseTranslation from "./controllers/Translation/Exercise/Exercise";
import ExerciseTranslationAdd from "./controllers/Translation/Exercise/ExerciseAdd";
import ExerciseTranslationEdit from "./controllers/Translation/Exercise/ExerciseEdit";
import UniversalAudioTranslation from "./controllers/Translation/UniversalAudio/UniversalAudio";
import UniversalAudioTranslationAdd from "./controllers/Translation/UniversalAudio/UniversalAudioAdd";
import UniversalAudioTranslationEdit from "./controllers/Translation/UniversalAudio/UniversalAudioEdit";
import AudioCategory from "./controllers/UniversalAudio/Category/AudioCategory";
import AudioCategoryAdd from "./controllers/UniversalAudio/Category/AudioCategoryAdd";
import AudioCategoryEdit from "./controllers/UniversalAudio/Category/AudioCategoryEdit";
import UniversalAudioEdit from "./controllers/UniversalAudio/Audio/UniversalAudioEdit";
import UniversalAudioAdd from "./controllers/UniversalAudio/Audio/UniversalAudioAdd";
import UniversalAudio from "./controllers/UniversalAudio/Audio/UniversalAudio";
import defaults from "./defaults";
import WorkoutTranslation from "./controllers/Translation/Workout/Workout";
import WorkoutTranslationEdit from "./controllers/Translation/Workout/WorkoutEdit";
import WorkoutTranslationAdd from "./controllers/Translation/Workout/WorkoutAdd";
import TrainingPlanTranslation from "./controllers/Translation/TrainingPlan/TrainingPlan";
import TrainingPlanTranslationAdd from "./controllers/Translation/TrainingPlan/TrainingPlanAdd";

let g = defaults.userGroups

const dashboardRoutes = [
    {
        exact: true,
        path: "/training_plans",
        name: "Training Plans",
        icon: "pe-7s-check",
        component: TrainingPlan,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        exact: true,
        submenu: true,
        path: "/training_plans/clusters",
        name: "Training Plans Clusters",
        component: TrainingPlanCluster,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        invisible: true,
        path: "/training_plans/clusters/add",
        name: "Add Training Plan Cluster",
        component: TrainingPlanClusterAdd,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },
    {
        submenu: true,
        invisible: true,
        path: "/training_plans/clusters/edit/:id",
        name: "Edit Training Plan Cluster",
        component: TrainingPlanClusterEdit,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },
    {
        invisible: true,
        path: "/training_plans/add",
        name: "Add Training Plan",
        icon: "pe-7s-check",
        component: TrainingPlanAdd,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },
    {
        invisible: true,
        path: "/training_plans/edit/:id",
        name: "Edit Training Plan",
        icon: "pe-7s-check",
        component: TrainingPlanEdit,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },
    {
        exact: true,
        path: "/workouts",
        name: "Workout",
        icon: "pe-7s-gym",
        component: Workout,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        invisible: true,
        path: "/workouts/add",
        name: "Workout Add",
        icon: "pe-7s-gym",
        component: WorkoutAdd,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },
    {
        invisible: true,
        path: "/workouts/edit/:id",
        name: "Workout Edit",
        icon: "pe-7s-check",
        component: WorkoutEdit,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },

    {
        exact: true,
        path: "/exercises",
        name: "Exercise",
        icon: "pe-7s-config",
        component: Exercise,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        invisible: true,
        path: "/exercise/add",
        name: "Exercise Add",
        icon: "pe-7s-config",
        component: ExerciseAdd,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },
    {
        invisible: true,
        path: "/exercise/edit/:id",
        name: "Exercise Edit",
        icon: "pe-7s-config",
        component: ExerciseEdit,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },
    {
        exact: true,
        path: "/workout-blocks",
        name: "Workout block",
        icon: "pe-7s-albums",
        component: WorkoutBlock,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        invisible: true,
        path: "/workout-blocks/add",
        name: "Workout Block Add",
        icon: "pe-7s-albums",
        component: WorkoutBlockAdd,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },
    {
        invisible: true,
        path: "/workout-blocks/edit/:id",
        name: "Workout Block Edit",
        icon: "pe-7s-albums",
        component: WorkoutBlockEdit,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },

    {
        exact: true,
        path: "/users",
        name: "Users",
        icon: "pe-7s-user",
        component: User,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer, g.support]
    },
    {
        invisible: true,
        path: "/users/:id/payment-info",
        name: "User Payment Info",
        icon: "pe-7s-user",
        component: UserPaymentInfo,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer, g.support]
    },
    {
        path: "/info",
        name: "Info",
        icon: "pe-7s-info",
        component: Info,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer, g.support]
    },

    // Universal Audio Categories
    {
        exact: true,
        path: "/universal-audio/categories",
        name: "Universal Audio",
        icon: "pe-7s-music",
        component: AudioCategory,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        exact: true,
        submenu: true,
        path: "/universal-audio/categories",
        name: "Audio Categories",
        component: AudioCategory,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        invisible: true,
        path: "/universal-audio/categories/add",
        name: "",
        component: AudioCategoryAdd,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },
    {
        invisible: true,
        path: "/universal-audio/categories/edit/:id",
        name: "",
        component: AudioCategoryEdit,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },
    // Universal Audio
    {
        exact: true,
        submenu: true,
        path: "/universal-audio",
        name: "Audio",
        component: UniversalAudio,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        invisible: true,
        path: "/universal-audio/add",
        name: "",
        component: UniversalAudioAdd,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },
    {
        invisible: true,
        path: "/universal-audio/edit/:id",
        name: "",
        component: UniversalAudioEdit,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },

    // Translations exercises
    {
        exact: true,
        path: "/translations/exercises",
        name: "Translations",
        icon: "pe-7s-pen",
        component: ExerciseTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        exact: true,
        submenu: true,
        path: "/translations/exercises",
        name: "Exercises",
        component: ExerciseTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/translations/exercises/add/:exercise_id/:language",
        name: "",
        component: ExerciseTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/translations/exercises/edit/:id",
        name: "",
        component: ExerciseTranslationEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },

    // Translations Universal Audio

    {
        exact: true,
        submenu: true,
        path: "/translations/universal-audio",
        name: "Universal Audio",
        component: UniversalAudioTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/translations/universal-audio/add/:universal_audio_track_id/:language",
        name: "",
        component: UniversalAudioTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/translations/universal-audio/edit/:id",
        name: "",
        component: UniversalAudioTranslationEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    // Translations Workouts
    {
        exact: true,
        submenu: true,
        path: "/translations/workouts",
        name: "Workouts",
        component: WorkoutTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/translations/workouts/add/:obj_id/:language",
        name: "",
        component: WorkoutTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/translations/workouts/edit/:id",
        name: "",
        component: WorkoutTranslationEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },

     // Translations Training Plan
    {
        exact: true,
        submenu: true,
        path: "/translations/training_plans",
        name: "Training Plan",
        component: TrainingPlanTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/translations/training_plans/add/:obj_id/:language",
        name: "",
        component: TrainingPlanTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/translations/training_plans/edit/:id",
        name: "",
        component: TrainingPlanTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },

];

export default dashboardRoutes;
