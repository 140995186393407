import React, {Component} from "react";
import List from "../../components/List/List";
import {WorkoutBlockFilter} from "../../models/WorkoutBlock";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {constToSelectOptions} from "../../utils/Utils";

export default class WorkoutBlock extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/workout-blocks/add';
        this.editPath = '/admin/workout-blocks/edit';
    }

    render() {
        let constants = this.context.constants;
        let workoutBlockTypes = constToSelectOptions(constants, 'workout_block_type')

        return (
            <List
                name="Workout Block"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/workout-blocks"
                listApiUrl='/admin/workout-blocks'
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'Internal name', row: 'internal_name'},
                    {col: 'Pace', row: 'pace'},
                    {
                        col: 'Type', row: 'block_type', normalizer: (data) => {
                            return workoutBlockTypes[data.type]
                        }
                    },
                    {col: 'Time', row: 'time'},
                    {col: 'Distance', row: 'distance'},
                    {col: 'Min heart rate', row: 'min_heart_rate'},
                    {col: 'Max heart rate', row: 'max_heart_rate'},
                    {col: 'Intro duration', row: 'intro_duration'},
                    {col: 'Repetitions', row: 'repetitions'},
                    {
                        col: 'Exercise', row: 'exercise', normalizer: (data) => {
                            return <div>{`Name: ${data.exercise.name} | Mets: ${data.exercise.mets} | Base pace: ${data.exercise.base_pace}`}</div>
                        }
                    },

                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={WorkoutBlockFilter(constants)}
                userHasPermission={this.props.userHasPermission}
            >
            </List>

        );
    }
}
