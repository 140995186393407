export default {
  "API_KEY": "p4KtF1IVAk75U9ohPpSOw1C8H6Q27fb969F7zOmK",
  "API_URL": "https://running-api-admin.asqq.io",
  "COGNITO_APP_CLIENT_ID": "4njgt0ncomk1f15frcld1liptf",
  "COGNITO_DOMAIN": "running-api-admin-prod.auth.us-east-2.amazoncognito.com",
  "COGNITO_REDIRECT_SIGN_IN": "https://running-admin.asqq.io/",
  "COGNITO_REDIRECT_SIGN_OUT": "https://running-admin.asqq.io/",
  "COGNITO_USER_POOL_ID": "us-east-2_pZneIMcUL",
  "REGION": "us-east-2"
}
