import React, {Component} from "react";
import List from "../../components/List/List";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {ExerciseFilter} from "../../models/Exercise";


export default class Exercise extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/exercise/add';
        this.editPath = '/admin/exercise/edit';
    }

    render() {
        let constants = this.context.constants;
        return (
            <List
                name="Exercises"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/exercises"
                listApiUrl='/admin/exercises'
                model={[
                    {col: 'Id', row: 'id'},
                    {
                        col: 'Preview', row: 'preview', normalizer: (data) => {
                            return <img alt={data.name} src={data.preview}
                                        width="150"/>
                        }
                    },
                    {col: 'Name', row: 'name'},
                    {col: 'Base Pace', row: 'base_pace'},
                    {col: 'Mets', row: 'mets'},
                    {
                        col: 'Description',
                        row: 'description',
                    }
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={ExerciseFilter(constants)}
                userHasPermission={this.props.userHasPermission}
            >
            </List>

        );
    }
}
