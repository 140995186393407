import {buildSelector} from "../utils/Utils";

export const TrainingPlanWorkout = (workouts) => {
    return {
        id: buildSelector(workouts, 'select',
            undefined,
            function (data) {
                // TODO fix workouts is empty (collection does not rerender)
                //  should fix Collection.jsx first
                return {value: data, label: data}
            }
        )
    }
}