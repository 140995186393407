import React, {Component} from "react";
import {Col, Grid, Row, Table} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import ReactPaginate from 'react-paginate';
import Loader from "../Loader/Loader";
import FilterForm from "../Form/FilterForm";
import EditButton from "./EditButton";
import AddButton from "./AddButton";
import API from '@aws-amplify/api';
import DeleteButton from "./DeleteButton";
import defaults from "../../defaults";

let g = defaults.userGroups


class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pagination_meta: {
                page: 1,
                per_page: 20,
                total_pages: 0,
                total_items: 0
            },
            filterParams: {},
            isLoading: true
        };

        let defaultGroups = [g.admin, g.editor];

        this.actionButtons = [
            (list, data_) => {
                return <EditButton
                    editPath={list.props.editPath}
                    id={data_.id}
                    allowedGroups={list.props.allowedEdit || defaultGroups}
                />

            },
            (list, data_) => {
                return <DeleteButton
                    id={data_.id}
                    handleRemove={list.handleRemove}
                    allowedGroups={list.props.allowedDelete || defaultGroups}
                />
            }
        ]

        this.headerButtons = [
            (list) => {
                return <AddButton
                    addPath={list.props.addPath}
                    allowedGroups={list.props.allowedAdd || defaultGroups}
                />
            }
        ]
    }

    componentDidMount() {
        this.getList(this.getAllQueryParams())
    }

    getList = params => {
        API.get('admin', this.props.listApiUrl, {'queryStringParameters': params}).then(data => {
            this.setState({
                data: data.items,
                isLoading: false,
                pagination_meta: data._meta
            });
        }).catch(error => {
            console.log(error);
        });
    };

    handlePageClick = page => {
        this.chooseNewPage(page['selected'] + 1);
    };

    handleFilterSubmit = data => {
        this.setState({filterParams: data}, () => {
            this.reloadData();
        });
    };
    chooseNewPage = page => {
        let new_pagination_meta = this.state.pagination_meta;
        new_pagination_meta['page'] = page;

        this.setState({
            pagination_meta: new_pagination_meta,
            isLoading: true
        }, () => {
            this.reloadData();
        });
    };

    reloadData = () => {
        this.setState({isLoading: true}, () => {
            this.getList(this.getAllQueryParams());
        });
    };

    getAllQueryParams = () => {
        return Object.assign({}, {
            page: this.state.pagination_meta.page,
            per_page: this.state.pagination_meta.per_page
        }, this.state.filterParams);
    };

    handleRemove = (id, event) => {
        API.del('admin', this.props.removeUrl + '/' + id)
            .then(data => {
                this.props.handleClick(this.props.name + " successfully removed", "success", "tr");
                this.chooseNewPage(1)
            }).catch(error => {
            this.props.handleClick(error.response.data.error || error.response.data.message, "error", "tr");
        });
    };

    filterActions = (list) => {
        return list.filter(
            i => this.props.userHasPermission(i(this, {}).props.allowedGroups)
        )
    }

    render() {
        // Define
        let actionButtons = this.props.actions || this.actionButtons
        let headerButtons = this.props.headerButtons
            || (this.props.addPath ? this.headerButtons : [])

        // Filter
        let actionButtonsFiltered = this.filterActions(actionButtons)
        let headerButtonsFiltered = this.filterActions(headerButtons)

        // Disable Actions row and header buttons?
        let isDisableActions = this.props.disableActions || actionButtonsFiltered.length === 0
        let isDisableHeaderButtons = this.props.disableHeaderButtons || headerButtonsFiltered.length === 0

        let filter;
        if (this.props.filterObject !== undefined) {
            filter = <FilterForm
                object={this.props.filterObject}
                onSubmit={this.handleFilterSubmit}
            />
        }

        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={this.props.name + " List"}
                                content={
                                    <div>
                                        {isDisableHeaderButtons ? undefined : headerButtonsFiltered.map(
                                            (component, index) => {
                                                return React.cloneElement(
                                                    component(this),
                                                    {key: index}
                                                );
                                            })}
                                        {filter}
                                    </div>
                                }
                            />
                            <Card
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <Loader isLoading={this.state.isLoading}>
                                        <Table striped hover>
                                            <thead>
                                            <tr>
                                                {this.props.model.map((prop, key) => {
                                                    return <th
                                                        key={key}>{prop.col}</th>;
                                                })}
                                                {
                                                    isDisableActions ? undefined :
                                                        <th>Actions</th>
                                                }
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.data.map((data, index) => {
                                                    return <tr key={index}>
                                                        {this.props.model.map((prop, index) => {
                                                            return <td
                                                                key={index}>
                                                                {prop.normalizer === undefined
                                                                    ? data[prop.row] || '-'
                                                                    : prop.normalizer(data)
                                                                }
                                                            </td>;
                                                        })}
                                                        {isDisableActions ? undefined :
                                                            <td className="table-actions">
                                                                <div
                                                                    className="btn-toolbar">
                                                                    {actionButtonsFiltered
                                                                        .map((component, index) => {
                                                                            return React.cloneElement(
                                                                                component(this, data),
                                                                                {key: index}
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            </td>}
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </Table>
                                        {this.state.pagination_meta && <Row>
                                            <Col md={12}>
                                                <ReactPaginate
                                                    previousLabel={'previous'}
                                                    nextLabel={'next'}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    pageCount={this.state.pagination_meta.total_pages}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={'pagination'}
                                                    forcePage={this.state.pagination_meta.page - 1}
                                                    subContainerClassName={'pages pagination'}
                                                    activeClassName={'active'}
                                                />
                                            </Col>
                                        </Row>}
                                    </Loader>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>

            </div>
        );
    }
}

export default List;
