export const ExerciseModel = (constants) => {
    return {
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },

        video_url: {
            type: 'input',
            validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
            value: null,
        },

        preview: {
            type: 'input',
            validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
            value: null,
        },

        description: {
            type: 'input',
            value: '',
        },

        audio_name: {
            type: 'input',
            validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
            value: null,
        },

        audio_description: {
            type: 'input',
            validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
            value: null,
        },

        base_pace: {
            inputType: 'number',
            value: '',
            requestNormalizer: (data) => {
                return parseFloat(data);
            }
        },
        mets: {
            inputType: 'number',
            value: '',
            requestNormalizer: (data) => {
                return parseFloat(data);
            }
        },
    };
}

export const ExerciseFilter = (constants) => {
    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            },
        },
        name: {
            type: 'input',
            value: '',
        },
    };
};


export const ExerciseEditModel = (constants) => {
    return {
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        video_url: {
            type: 'input',
            validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
            value: ''
        },
        preview: {
            type: 'input',
            validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        description: {
            type: 'input',
            value: '',
        },
        audio_name: {
            type: 'input',
            validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        base_pace: {
            inputType: 'number',
            value: '',
            requestNormalizer: (data) => {
                return parseFloat(data);
            }
        },
        mets: {
            inputType: 'number',
            value: '',
            requestNormalizer: (data) => {
                return parseFloat(data);
            }
        },

    };
}