import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";
import {UniversalAudioTranslationFilter} from "../../../models/UniversalAudioTranslation";
import {API} from "aws-amplify";

export default class UniversalAudioTranslation extends BaseTranslationComponent {
    constructor(props) {
        super(props);
        this.name = "Universal Audio Translations"
        this.addPath = "/admin/translations/universal-audio/add";
        this.editPath = "/admin/translations/universal-audio/edit";
        this.removeUrl = "/admin/translations/universal-audio";
        this.listApiUrl = "/admin/translations/universal-audio";
        this.state = {
            categories: [],
        }
    }

    componentDidMount() {
        API.get('admin', "/admin/universal-audio/categories")
            .then(data => {
                let categories = data.items.reduce(function (obj, item) {
                    obj[item.id] = `${item.name}`;
                    return obj;
                }, {});
                this.setState({
                    categories: categories

                })
            }).catch(error => {
            console.error(error)
        })

    }

    generateModel = () => {
        let constants = this.context.constants;
        let languages = Object.values(constants.language === undefined ? {} : constants.language.items || {});
        languages.sort(
            (a, b) => a.value - b.value
        );
        let model = [{col: 'Id', row: 'id'}, {col: 'Internal name', row: 'internal_name'}];
        for (const lang of languages) {
            model.push({
                col: lang.title,
                row: "languages",
                normalizer: (data) => {
                    return this.generateAddEditLink(data.translations[lang.key], data.id, lang)
                }
            });
        }
        return model;
    }


    generateFilter = () => {
        return UniversalAudioTranslationFilter(this.state.categories)
    }
}
