import React, {Component} from "react";
import List from "../../components/List/List";
import {UserFilter} from "../../models/User";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {NavLink} from "react-router-dom";
import Button from "react-bootstrap/lib/Button";
import defaults from "../../defaults";
import {API} from "aws-amplify";

let g = defaults.userGroups
const RESTORE_PASSWORD_URL = '/admin/client-users/restore-password'

class User extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.editPath = '/admin/users/edit';
    }

    handleChangeIsPaid = ( event, userId ) => {
        if (window.confirm('Are you sure? Is paid status will be changed.')) {
            API.post('admin', `/admin/client-users/${userId}/change-is-paid`)
                .then(data => {
                    alert(`Status changed successfully!`);
                })
                .catch(error => {
                    alert('Something went wrong');
                })
        }
    };

    handleRestorePassword = ( event, userEmail ) => {
        if (window.confirm('Are you sure?')) {
            API.post('admin', `${RESTORE_PASSWORD_URL}`, {body: {email: userEmail}})
                .then(data => {
                    alert(`Password successfully restored to ${userEmail}`);
                })
                .catch(error => {
                    alert('Something went wrong');
                })
        }
    };

    render() {
        let get_const = this.context.get_const
        return (
            <List
                name="Users"
                editPath={this.editPath}
                removeUrl="/admin/client-users"
                listApiUrl='/admin/client-users'
                actions={[
                    (list, data_) => {
                        return <Button
                            allowedGroups={[g.admin, g.editor]}
                            className="btn btn-fill btn-danger btn-xs"
                            onClick={(e) => list.handleRemove(data_.id, e)}>
                            <i className="fa fa-times"/>
                        </Button>
                    },
                    (list, data) => {
                        return <NavLink
                            to={{
                                pathname: `users/${data.id}/payment-info`,
                                state: {
                                    user_id: data.id,
                                    user_platform: data.platform
                                }
                            }}
                            className="btn btn-fill btn-success btn-xs"
                        >
                            <i className="fa fa-dollar"/>
                        </NavLink>
                    },
                    (list, data_) => {
                        return <Button allowedGroups={[g.admin, g.support]}
                           className="btn btn-fill btn-warning btn-xs"
                           key="restorePassword"
                           onClick={(e) => this.handleRestorePassword(e, data_.email)}>
                            <i className="fa fa-key"/>
                        </Button>
                    },
                    (list, data_) => {
                        return <Button allowedGroups={[g.admin, g.support]}
                           className="btn btn-fill btn-primary btn-xs"
                           key="changeIsPaid"
                           onClick={(e) => this.handleChangeIsPaid(e, data_.id)}>
                            <i className="fa fa-check"/>
                        </Button>
                    },
                ]}
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'Email', row: 'email'},
                    {col: 'Name', row: 'name'},
                    {
                        col: 'Is Paid', row: 'is_paid', normalizer: (data) => {
                            return data.is_paid ? 'Yes' : 'No'
                        }
                    },
                    {col: 'Locale', row: 'locale'},
                    {col: 'Age', row: 'age'},
                    {
                        col: 'Bmi Level',
                        row: 'bmi_level',
                        normalizer: (data) => {
                            return get_const('bmi', data.bmi_level)
                        }
                    },
                    {col: 'Bmi Value', row: 'bmi_value'},
                    {
                        col: 'Cluster Id',
                        row: 'cluster_id',
                        normalizer: (data) => {
                            return data.cluster_id || "N/A"
                        }
                    },
                    {col: 'Timezone', row: 'timezone'},
                    {col: 'Created At', row: 'created_at'},
                    {col: 'Client Version', row: 'client_version'},
                    {col: 'Fitness Level', row: 'fitness_level'},
                    {col: 'Goal', row: 'goal'},
                    {col: 'Weight', row: 'weight'},
                    {col: 'Height', row: 'height'},
                    {col: 'Platform', row: 'platform'},
                    {
                        col: 'Is password set', row: 'is_password_set',
                        normalizer: (data) => {
                            return data.is_password_set ? 'Yes' : 'No'
                        }
                    },
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={UserFilter}
                userHasPermission={this.props.userHasPermission}
            >
            </List>

        );
    }
}

export default User;
