import {buildSelector} from "../utils/Utils";

export const UniversalAudioModel = (categories) => {
    return {
        category_id: buildSelector(
            categories,
            'select',
            (data) => {
                return parseInt(data.value);
            }
        ),
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        internal_name: {
            type: 'input',
            validationRules: ['required', {'regex': "^([a-z0-9]+)(_[a-z0-9]+)*$"}],
            value: '',
        },
        audio_url: {
            type: 'input',
            validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
        },
    };
}

export const UniversalAudioFilter = (categories) => {
    return {
        name: {
            type: 'input',
            value: '',
        },
        category_id:  buildSelector(
            categories,
            'select',
            (data) => {
                return parseInt(data.value);
            }),
    };
}