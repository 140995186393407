import {components} from "react-select";
import React from "react";
import {API} from "aws-amplify";
import {NavLink} from "react-router-dom";
import {buildSelector, constToSelectOptions} from "../utils/Utils";

const Option = ({children, ...props}) => {
    let option = props.data
    return (
        <components.Option {...props}>
            <div className="clearfix">
                <div className="mb-3 pull-left">
                    <img alt={"Preview"} className="pull-left mr-2 img-rounded"
                         width="128"
                         height="128"
                         src={option.exercise.preview}/>
                </div>
                <p style={{"margin": "0"}}>
                        <NavLink
                            to={{
                                pathname: "/admin/workout-blocks/edit/" + option.id,
                            }}
                            target="_blank"
                            style={{color: 'black'}}
                        >
                            <strong>[{option.id}] {option.internal_name}</strong> <span
                            className="glyphicon glyphicon-new-window"/>
                        </NavLink>
                    </p>

                <div className="mb-9 pull-left" style={{"marginLeft": "10px"}}>
                    {/*<p style={{"margin": "0"}}><strong>{`[${option.id}] ${option.internal_name}`}</strong></p>*/}
                    <p style={{"margin": "0"}}><strong>{`Name: ${option.exercise.name}`}</strong></p>
                    <p style={{"margin": "0"}}><strong>{`Block Type: ${option.block_type}`}</strong></p>
                    <span style={{"margin": "0"}}>
                        Time/Distance/Repetitions: <strong>{option.time}</strong>/<strong>{option.distance}</strong>/<strong>{option.repetitions}</strong>
                    </span><br/>
                    <span style={{"margin": "0"}}>
                        Heart Rate min/Heart Rate Max: <strong>{option.min_heart_rate}</strong>/<strong>{option.max_heart_rate}</strong>
                    </span><br/>
                </div>
            </div>
        </components.Option>
    );
};


export const WorkoutBlockIds = (constants, loadOptions) => {
    let objCache = {}
    return {
        id: {
            type: 'select',
            validationRules: 'required',
            onChangeEvent: 'handleSelect',
            value: '',
            requestNormalizer: (option) => {
                console.log("requestNormalizer", option)
                return option.id
            },
            responseNormalizer: (option) => {
                let block_id = option
                if (objCache[block_id] !== undefined) {
                    return objCache[block_id]
                }
                return API.get('admin', `/admin/workout-blocks/${block_id}`)
                    .then(data => {
                        objCache[block_id] = data;
                        return data;
                    }).catch(error => {
                        console.error(error)
                        return {}
                    })
            },
            selectProps: {
                components: {Option},
                getOptionLabel: option => {
                    return `${option['internal_name']}; ${option['block_type']}; ${option['pace']}; ${option['exercise']['name']}`
                },
                getOptionValue: option => {
                    return option['id']
                },
                pageSize: 10,
                minMenuHeight: 300,
                maxMenuHeight: 1000,
                loadOptions: loadOptions,
                // defaultOptions: true,
            },
            md: 10
        },

    }
}

const ExerciseOption = ({children, ...props}) => {
    let option = props.data
    return (
        <components.Option {...props}>
            <div className="clearfix">
                <div className="mb-3 pull-left">
                    <img alt={"Preview"} className="pull-left mr-2 img-rounded"
                         width="128"
                         height="128"
                         src={option.exercise.preview}/>
                </div>
                <div className="mb-9 pull-left" style={{"marginLeft": "10px"}}>
                    <p style={{"margin": "0"}}><strong>{`[${option.id}] ${option.name}`}</strong></p>
                    <p style={{"margin": "0"}}><strong>{`Mets: ${option.exercise.mets}`}</strong></p>
                    <p style={{"margin": "0"}}><strong>{`Base Pase: ${option.base_pace}`}</strong></p>
                </div>
            </div>
        </components.Option>
    );
};

export const WorkoutBlock = (constants, loadOptions) => {
    let objCache = {}
    return {
        exercise_id: {
            type: 'select',
            validationRules: 'required',
            onChangeEvent: 'handleSelect',
            value: '',
            requestNormalizer: (option) => {
                console.log("requestNormalizer", option)
                return option.id
            },
            responseNormalizer: (option) => {
                let exercise_id = option
                if (objCache[exercise_id] !== undefined) {
                    return objCache[exercise_id]
                }
                return API.get('admin', `/admin/exercises/${exercise_id}`)
                    .then(data => {
                        objCache[exercise_id] = data;
                        return data;
                    }).catch(error => {
                        console.error(error)
                        return {}
                    })
            },
            selectProps: {
                components: {ExerciseOption},
                getOptionLabel: option => {
                    return `[${option['id']}]; ${option['name']};`
                },
                getOptionValue: option => {
                    return option['id']
                },
                pageSize: 10,
                minMenuHeight: 300,
                maxMenuHeight: 1000,
                loadOptions: loadOptions,
            },
            md: 5
        },
        internal_name: {
            type: 'input',
            value: '',
            md: 5
        },
        block_type: buildSelector(constToSelectOptions(constants, 'workout_block_type'), 'select', undefined, undefined, 5),
        pace: {
            ...buildSelector(constToSelectOptions(constants, 'pace'), 'select'),
            value: null,
            md: 5,
            validationRules: ''
        },

        time: {
            type: 'input',
            md: 5,
            value: '',
            requestNormalizer: function (data) {
                return parseInt(data);
            },
        },
        min_heart_rate: {
            type: 'input',
            md: 5,
            value: '',
            requestNormalizer: function (data) {
                return parseInt(data);
            },
        },
        max_heart_rate: {
            type: 'input',
            md: 5,
            value: '',
            requestNormalizer: function (data) {
                return parseInt(data);
            },
        },
        intro_duration: {
            type: 'input',
            md: 5,
            value: '',
            requestNormalizer: function (data) {
                return parseInt(data);
            },
        },
        distance: {
            type: 'input',
            md: 5,
            value: '',
            requestNormalizer: function (data) {
                return parseInt(data);
            },
        },
        repetitions: {
            type: 'input',
            md: 5,
            value: '',
            requestNormalizer: function (data) {
                return parseInt(data);
            },
        },

    }
};

export const WorkoutBlockFilter = (constants) => {
    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            },
        },
        internal_name: {
            type: 'input',
            value: '',
        },
    };
};